import { formatDecimal } from '@/lib/currency/currency'

export default {
  name: 'CartCommon',
  data () {
    return {
      IsLoading    : false,
      OrderTypeEnum: {
        Delivery  : 1,
        Collection: 2
      },
      OrderSourceTypeEnum: {
        All: {
          ONLINE                : 1,
          VOIP                  : 2,
          TELEPHONY             : 3,
          TABLE                 : 4,
          POS                   : 5,
          ONLINE_INTERNAL_TABLE : 6,
          ONLINE_INTERNAL_BOOTH : 7,
          ONLINE_INTERNAL_ROOM  : 8,
          ONLINE_INTERNAL_SUNBED: 9,
          MOBILE                : 10
        },
        Default: {
          ONLINE   : 1,
          VOIP     : 2,
          TELEPHONY: 3,
          TABLE    : 4,
          POS      : 5,
          MOBILE   : 10
        },
        Qr: {
          ONLINE_INTERNAL_TABLE : 6,
          ONLINE_INTERNAL_BOOTH : 7,
          ONLINE_INTERNAL_ROOM  : 8,
          ONLINE_INTERNAL_SUNBED: 9
        }
      }
    }
  },

  computed: {
    selectedPaymentMethod: {
      set (val) {
        this.dataStore.selectedPaymentMethod = val
      },
      get () {
        return this.dataStore.selectedPaymentMethod
      }
    },
    selectedPaymentMethodSupportsTips () {
      return this.appConfig?.LOCATION_DATA?.OrderTips && this.selectedPaymentMethod?.tip
    },
    orderComment: {
      set (val) {
        this.dataStore.orderComment = val
      },
      get () {
        return this.dataStore.orderComment
      }
    },
    isCartEnabled () {
      if (this.dataStore.cart) return ((this.dataStore.cart.IsOpened && !this.dataStore.cart.IsClosed) || (!this.dataStore.cart.HasDelivery && !this.dataStore.cart.HasCollection))
      return false
    },
    isDelivery () {
      return this.dataStore.cart && parseInt(this.dataStore.cart.OrderType) === this.OrderTypeEnum.Delivery
    },
    isCollection () {
      return this.dataStore.cart && parseInt(this.dataStore.cart.OrderType) === this.OrderTypeEnum.Collection
    },
    hasDelivery () {
      return this.dataStore.cart && this.dataStore.cart.DeliveryStatus === 'open' && this.dataStore.cart.HasDelivery
    },
    hasCollection () {
      return this.dataStore.cart && this.dataStore.cart.CollectionStatus === 'open' && this.dataStore.cart.HasCollection
    },
    cartHasCoupon () {
      return this.dataStore?.cart?.CartTotals?.coupon ?? false
    },
    hasActiveCoupons () {
      return this.appConfig?.APP_SETTINGS?.HasActiveCoupons ?? false
    },
    hasActiveCouponForAutoApply () {
      return (this.appConfig?.APP_SETTINGS?.HasActiveCouponForAutoApply && this.appConfig?.APP_SETTINGS?.HasActiveCouponForAutoApply?.order_restriction === parseInt(this.dataStore?.cart?.OrderType)) ? this.appConfig?.APP_SETTINGS?.HasActiveCouponForAutoApply : false
    },
    canCustomerAddCoupon () {
      return this.appConfig?.APP_SETTINGS?.CanCustomerAddCoupon ?? false
    },
    cartItems () {
      return this.dataStore.cart ? this.dataStore.cart.CategoryItems || [] : []
    },
    autoApplyCouponDiscount () {
      return this.hasActiveCouponForAutoApply?.type === 'P' ? formatDecimal(this.$i18n.locale, this.hasActiveCouponForAutoApply?.discount, 2, '%') : this.$options.filters.currency(this.hasActiveCouponForAutoApply?.discount)
    },
    autoApplyCouponMinTotalAmountValid () {
      const couponMinTotalAmount = parseFloat(this.hasActiveCouponForAutoApply?.min_total)
      return (couponMinTotalAmount - this.orderCartTotalAmount) <= 0
    },
    autoApplyCouponMinTotalAmountDiff () {
      const couponMinTotalAmount = parseFloat(this.hasActiveCouponForAutoApply?.min_total)
      return couponMinTotalAmount - this.orderCartTotalAmount
    },
    orderTotalAmountValid () {
      return this.orderTotalAmountValidPayment(this.selectedPaymentMethod)
    },
    orderMinTotalAmountDiff () {
      return this.orderMinTotalAmountDiffPayment(this.selectedPaymentMethod)
    },
    cartTotalAmount () {
      return parseFloat(this.dataStore?.cart?.CartTotals?.order_total?.amount) || 0
    },
    orderCartTotalAmount () {
      return parseFloat(this.dataStore?.cart?.CartTotals?.cart_total?.amount) || 0
    },
    minOrderTotal () {
      return this.minOrderTotalPayment(this.selectedPaymentMethod)
    },
    minCollectionOrderTotal () {
      return this.appConfig?.LOCATION_DATA?.OrderTotalCollectionMin || 0
    },
    minDeliveryOrderTotal () {
      return this.appConfig?.LOCATION_DATA?.OrderTotalDeliveryMin || 0
    },
    allowGuestOrders () {
      return !this.appConfig.LOCATION_DATA.AllowGuestOrders && !this.dataStore.isAuthenticated
    },
    showOrderTypes () {
      return this.$route.name !== 'CheckoutPayment' && (this.appSettings.ShowOrderTypes)
    },
    isQrOrderWithCustomerData () {
      return !(this.isQrOrder && !this.appSettings?.QrOnlineWithCustomer)
    },
    isQrOrderWithCustomerDataRequired () {
      return this.isQrOrderWithCustomerData && this.appSettings?.QrOnlineWithCustomerRequired
    },
    isQrOrder () {
      return !!this.dataStore.qrData && this.dataStore.isQRScanApp
    },
    isQrOnlyOrder () {
      return this.appSettings.QrOnly || false
    },
    defaultOrderType () {
      return this.appSettings.DefaultOrderType || '1'
    },
    QrData () {
      return this.dataStore.qrData
    },
    CartPromiseQueue () {
      return window.CartPromiseQueue
    }
  },

  methods: {
    cartItemTotal (item, withQuantity = true) {
      return parseFloat(item.Price) * (withQuantity ? parseInt(item.Quantity) : 1)
    },

    orderTotalAmountValidPayment (payment) {
      return this.orderMinTotalAmountDiffPayment(payment) <= 0
    },

    orderMinTotalAmountDiffPayment (payment) {
      return this.minOrderTotalPayment(payment) - this.orderCartTotalAmount
    },

    minOrderTotalPayment (payment) {
      let retVal = 0
      payment = payment || this.appSettings?.Payments[0] || null
      const paymentMinOrderTotal = parseFloat(payment?.data?.minimum_order_total || 0)
      if (!payment) return retVal

      if (this.isCollection) retVal = this.minCollectionOrderTotal
      if (this.isDelivery) retVal = this.minDeliveryOrderTotal
      if (paymentMinOrderTotal > retVal) retVal = paymentMinOrderTotal

      return retVal
    }
  }
}
